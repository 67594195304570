<router-outlet>
  <header>
    <app-menu></app-menu>
    <app-banner>
    </app-banner>
  </header>
  <main>
    <app-about></app-about>
    <app-products-and-services></app-products-and-services>
    <app-customers></app-customers>
    <app-opportunities></app-opportunities>
    <app-depositions></app-depositions>
<!--    <app-blog></app-blog>-->
    <app-contact></app-contact>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</router-outlet>
