<section class="sessaoRP container" id="about">
  <h2 class="tittle">Sobre a RP</h2>
  <div class="cont-rp row">
    <div class="box-rp col-md-6">
      <h3 class="mt-0">História</h3>
      <p>A REDEPOS atua no mercado desde 2008 desenvolvendo e
        comercializando Soluções, produtos e serviços de valor agregado, para
        tecnologias e dispositivos móveis; Especializada em captura e
        processamento de transações eletrônicas através de terminais
        wireless; Desenvolve soluções de entretenimento e informação baseada
        em tecnologias móveis.</p>
      <p>Formada por profissionais altamente capacitados e com ampla
        experiência no desenvolvimento de soluções tecnológicas
        para o mercado;</p>
      <p>Áreas: Sistemas comerciais, entretenimento e comunicação;</p>
      <p>Plataformas: Android, iOS, Symbian OS, BREW, Flash Lite, Windows
        Mobile, Mobile WEB, SMS;
      </p>
      <p>Linguagens: Java, JavaScript, PHP, Python, C/C++, Xojo, Go.</p>
    </div>
    <div class="box-rp col-md-6">
      <h3>Missão</h3>
      <p>“Proporcionar melhoria de vida às pessoas”</p>
      <p>Assim garantiremos a continuidade da nossa empresa e a satisfação dos
        nossos clientes, colaboradores e investidores. </p>
      <h3>Valores</h3>
      <ul>
        <li>Ética</li>
        <li>Transparência</li>
        <li>Comprometimento</li>
        <li>Trabalho em equipe</li>
        <li>Meritocracia</li>
        <li>Simplicidade</li>
      </ul>
    </div>
  </div>
</section>
