<section class="sessaoRP" id="customers">
  <div class="container">
    <h2 class="tittle">Clientes</h2>
    <owl-carousel-o [options]="slideBases">
      <ng-template carouselSlide>
        <a href="https://sp.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/sao-paulo-da-sorte.png" alt="São Paulo dá Sorte">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://pe.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/pernambuco-da-sorte.png" alt="PE dá Sorte">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://es.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/capixaba-cap.png" alt="Capixaba Cap">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://am.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/amazonas-da-sorte.png" alt="Amazonas dá Sorte">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://pa.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/carimbo-da-sorte.png" alt="Carimbó dá Sorte">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://al.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/alagoas-da-sorte.png" alt="Alagoas dá Sorte">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://ba.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/bahia-da-sorte.png" alt="Bahia dá Sorte">
        </a>
      </ng-template>
      <ng-template carouselSlide>
        <a href="https://go.dasorte.com/vendas/comprar-titulos" target="_blank">
          <img src="assets/img/base/goias-da-sorte.png" alt="Goiás dá Sorte">
        </a>
      </ng-template>
    </owl-carousel-o>
  </div>
</section>

