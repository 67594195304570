import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  formContato: FormGroup;
  submitForm = false;

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.formContato = this.fb.group({
      nome: [null, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(60)])],
      email: [null, Validators.compose([Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(80)])],
      telefone: [null, Validators.compose([Validators.required, Validators.minLength(14), Validators.maxLength(15)])],
      msg: [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(240)])]
    });
  }

  // enviarFormContato() {
  //   this.submitForm = true;
  //   if (this.formContato.valid) {
  //     // Swal('Verifique e preencha os campos obrigatórios e tente novamente.');
  //     return;
  //   } else {
  //     return('Sucesso');
  //   }
  // }

}
