import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BannerComponent } from './section/banner/banner.component';
import { AboutComponent } from './section/about/about.component';
import { ProductsAndServicesComponent } from './section/products-and-services/products-and-services.component';
import { CustomersComponent } from './section/customers/customers.component';
import { OpportunitiesComponent } from './section/opportunities/opportunities.component';
import { DepositionsComponent } from './section/depositions/depositions.component';
import { BlogComponent } from './section/blog/blog.component';
import { ContactComponent } from './section/contact/contact.component';
import { FooterComponent } from './section/footer/footer.component';
import { CardComponent } from './components/card/card.component';
import { CardDepositionsComponent } from './components/card-depositions/card-depositions.component';
import { BoxImageComponent } from './components/box-image/box-image.component';
import { TitleComponent } from './components/title/title.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import { MenuComponent } from './components/menu/menu.component';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatCarouselModule} from '@ngmodule/material-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {MatCardModule} from '@angular/material/card';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {MatExpansionModule} from '@angular/material/expansion';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    AboutComponent,
    ProductsAndServicesComponent,
    CustomersComponent,
    OpportunitiesComponent,
    DepositionsComponent,
    BlogComponent,
    ContactComponent,
    FooterComponent,
    CardComponent,
    CardDepositionsComponent,
    BoxImageComponent,
    TitleComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatExpansionModule,
    MatCardModule,
    CarouselModule,
    MatCarouselModule.forRoot(),
    MatMenuModule,
    NgbModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
