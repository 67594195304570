<section class="sessaoRP bg-opp" id="opportunities">
  <div class="container">
    <h2 class="tittle">Oportunidades</h2>
    <div class="row">
      <!--Vagas-->
      <div class="col-md-6">
        <h4>Vagas disponíveis</h4>
        <div class="cont-vagas">

          <div class="example-action-buttons">
            <button mat-button (click)="accordion.openAll()">Expandir tudo</button>
            <button mat-button (click)="accordion.closeAll()">Fechar tudo</button>
          </div>
          <mat-accordion class="example-headers-align" multi>
<!--            Desenvolvedor Web Full Stack-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5 class="vagas-tit">Desenvolvedor Web Full Stack</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="box-vagas">
                <p><strong>O que fazemos?</strong></p>
                <p>- Construímos soluções de venda escaláveis e robustas para uma série de
                  plataformas: <strong>pontos de venda (maquininhas), web, aplicativos e desktop.</strong></p>
              </div>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>Responsabilidades</strong></p>
                <p>- Trabalhar no desenvolvimento de aplicações web desde a integração com
                  serviços até a interface de usuário e sua experiência;</p>
                <p>- Participação no levantamento de requisitos e planejamento das atividades.</p>
              </div>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>O que buscamos?</strong></p>
                <p>- Desenvolvedor Web familiarizado com as principais tecnologias <strong>web
                  (html, css e js)</strong> e algum dos frameworks modernos <strong>(Angular, React ou Vue);</strong></p>
                <p>- Noções de design responsivo;</p>
                <p>- Noções de versionamento de código e padrões de projeto.</p>
              </div>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>Perfil pessoal</strong></p>
                <p>- Excelente habilidade para trabalhar em equipe;</p>
                <p>- Forte comunicação;</p>
                <p>- Facilidade para aprender novas tecnologias;</p>
                <p>- Proatividade;</p>
                <p>- Soluções rápidas;</p>
              </div>
            </mat-expansion-panel>
<!--            Desenvolvedor Mobile-->
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <h5 class="vagas-tit">Desenvolvedor Mobile</h5>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>Responsabilidades</strong></p>
                <p>Desenvolvedor de aplicações Android e iOS, manutenção e testes unitários
                  utilizando Flutter e metodologias ágeis.</p>
              </div>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>Conhecimentos necessários</strong></p>
                <p>- Experiência com Flutter;</p>
                <p>- Experiência com integrações nativas (Android e iOs);</p>
                <p>- Conhecimentos básicos em back-end (GO);</p>
                <p>- Ferramenta de versionamento Git.</p>
              </div>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>Conhecimentos desejáveis</strong></p>
                <p>- Conhecimentos em JavaScript, Angular ou outros frameworks;</p>
                <p>- Experiência com Jira.</p>
              </div>
              <div class="box-vagas">
                <p class="vagas-subtit"><strong>Perfil pessoal</strong></p>
                <p>- Excelente habilidade para trabalhar em equipe;</p>
                <p>- Forte comunicação;</p>
                <p>- Facilidade para aprender novas tecnologias;</p>
                <p>- Proatividade;</p>
                <p>- Soluções rápidas;</p>
              </div>
            </mat-expansion-panel>
<!--            Desenvolvedor Java-->
<!--            <mat-expansion-panel>-->
<!--              <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                  <h5 class="vagas-tit">Desenvolvedor Java <span class="titp">(Junior/Pleno)</span></h5>-->
<!--                </mat-panel-title>-->
<!--              </mat-expansion-panel-header>-->
<!--              <div class="box-vagas">-->
<!--                <p><strong>Descrição da vaga</strong></p>-->
<!--                <p>Ter experiencia com desenvolvimento ágil, experiência com desenvolvimento-->
<!--                  preferencialmente Java desktop, multiplataforma, com conexões a vários tipos-->
<!--                  de impressoras fiscais, conhecimento em integraco a rest full e sistemas de-->
<!--                  atualização remota e versionada.</p>-->
<!--              </div>-->
<!--              <div class="box-vagas">-->
<!--                <p class="vagas-subtit"><strong>Necessário</strong></p>-->
<!--                <p>- Experiência comprovada em Java;</p>-->
<!--                <p>- Domínio de JUnit;</p>-->
<!--                <p>- Conhecimento em diferentes modelos de arquitetura.</p>-->
<!--              </div>-->
<!--              <div class="box-vagas">-->
<!--                <p class="vagas-subtit"><strong>Diferencial</strong></p>-->
<!--                <p>Conhecimentos em GO, Oracle e coushbase</p>-->
<!--              </div>-->
<!--              <div class="box-vagas">-->
<!--                <p class="vagas-subtit"><strong>Perfil pessoal</strong></p>-->
<!--                <p>- Excelente habilidade para trabalhar em equipe;</p>-->
<!--                <p>- Forte comunicação;</p>-->
<!--                <p>- Facilidade para aprender novas tecnologias;</p>-->
<!--                <p>- Proatividade;</p>-->
<!--                <p>- Soluções rápidas;</p>-->
<!--              </div>-->
<!--            </mat-expansion-panel>-->
<!--            DBA - Administrador de Banco de Dados-->
<!--            <mat-expansion-panel>-->
<!--              <mat-expansion-panel-header>-->
<!--                <mat-panel-title>-->
<!--                  <h5 class="vagas-tit">DBA - Administrador de Banco de Dados <span class="titp">(Pleno/Sênior)</span></h5>-->
<!--                </mat-panel-title>-->
<!--              </mat-expansion-panel-header>-->
<!--              <div class="box-vagas">-->
<!--                <p><strong>O que buscamos?</strong></p>-->
<!--                <p>- Experiência em administração e análise de banco de dados com pelo menos 5 anos de mercado;</p>-->
<!--                <p>- Conhecimento em Oracle database e PostgreSQL necessários;</p>-->
<!--                <p>- Conhecimento em Couchbase desejável.</p>-->
<!--              </div>-->
<!--              <div class="box-vagas">-->
<!--                <p class="vagas-subtit"><strong>Atividades</strong></p>-->
<!--                <p>- Remodelagem de banco de dados de aplicação de médio porte;</p>-->
<!--                <p>- Migração de banco proprietário para banco Open Source;</p>-->
<!--                <p>- Manutenção dos dados.</p>-->
<!--              </div>-->
<!--              <div class="box-vagas">-->
<!--                <p class="vagas-subtit"><strong>Perfil pessoal</strong></p>-->
<!--                <p>- Excelente habilidade para trabalhar em equipe;</p>-->
<!--                <p>- Forte comunicação;</p>-->
<!--                <p>- Facilidade para aprender novas tecnologias;</p>-->
<!--                <p>- Proatividade;</p>-->
<!--                <p>- Soluções rápidas;</p>-->
<!--              </div>-->
<!--            </mat-expansion-panel>-->
          </mat-accordion>
        </div>
      </div>
      <div style="padding-top: 4.5em" class="col-md-6">
<!--        <p>Se você ficou com vontade de fazer parte da nossa equipe, preencha o formulário abaixo e envia teu currículo ;)</p>-->
        <p>Se você ficou com vontade de fazer parte da nossa equipe, envia teu currículo para o e-mail <strong>oportunidades@redepos.com.br</strong> e inclui no assunto o nome da vaga, blz?<br> Sucesso!! <strong>;)</strong></p>
<!--        <form id="form-oportunidade" (ngSubmit)="enviarFormOportunidade()" [formGroup]="formulario">-->
<!--          <div class="formRP">-->
<!--            <label for="nome">Nome</label>-->
<!--            <input type="text" id="nome" name="nome" formControlName="nome">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="email">E-mail</label>-->
<!--            <input type="text" id="email" name="email" formControlName="email">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="telefone">Telefone</label>-->
<!--            <input type="text" id="telefone" name="telefone" formControlName="telefone">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="vaga">Nome da vaga</label>-->
<!--            <input type="text" id="vaga" name="vaga" formControlName="vaga">-->
<!--          </div>-->
<!--          <div class="formRP">-->
<!--            <label for="anexo">Anexo</label>-->
<!--            <input type="file" id="anexo" name="anexo" formControlName="anexo">-->
<!--          </div>-->
<!--          <div class="cont-button">-->
<!--            <button class="btn-rp" type="button" (click)="enviarFormOportunidade()">Enviar</button>-->
<!--          </div>-->
<!--        </form>-->
      </div>
    </div>
  </div>
</section>

