<mat-toolbar>
  <div class="container topo longDiv">
    <a href="#"><img src="assets/img/logo-redepos.png" alt="Logo da RedePos" height="25px"></a>
    <span class="example-spacer"></span>
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item href="#about">Sobre</a>
      <a mat-menu-item href="#products">Produtos e Serviços</a>
      <a mat-menu-item href="#customers">Clientes</a>
      <a mat-menu-item href="#opportunities">Oportunidades</a>
      <a mat-menu-item href="#depositions">Depoimentos</a>
<!--      <a mat-menu-item href="#blog">Blog</a>-->
      <a mat-menu-item href="#contact">Contato</a>
    </mat-menu>
  </div>
</mat-toolbar>

