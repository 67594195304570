<section class="sessao1">
  <div class="container">
    <div class="cont-footer">
      <div>
        <img src="assets/img/logo-footer.png" alt="Logo RedePos">
      </div>

      <div class="box-menu-footer">
        <ul class="mr-5">
          <h6>Menu</h6>
          <a href="#redePos"><li>Inicio</li></a>
          <a href="#about"><li>Sobre a RP</li></a>
          <a href="#products"><li>Produtos e Serviços</li></a>
          <a href="#customers"><li>Clientes</li></a>
        </ul>
        <ul class="map2">
          <a href="#opportunities"><li>Oportunidades</li></a>
          <a href="#depositions"><li>Depoimentos</li></a>
          <a href="#contact"><li>Contato</li></a>
<!--          <li>Blog</li>-->
        </ul>
      </div>

      <div class="map3">
        <div class="redefooter">
          <h6>Segue a gente</h6>
          <a href="https://www.linkedin.com/company/redepos/" target="_blank"><img src="assets/img/icon-linkedin.png" alt="Icone Linkedin"></a>
        </div>
        <div class="scroll-to-top" [ngClass]="{'show-scrollTop': windowScrolled}">
          <button (click)="scrollToTop()" class="irptopo">
            <span class="material-icons">keyboard_arrow_up</span>
          </button>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="sessaoO footercopy">
  <div class="container">
    <p>Copyright &copy; - RedePos Tecnologia, Comércio de Equipamentos e Promoção de Vendas LTDA 2022</p>
  </div>
</section>
