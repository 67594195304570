<section class="sessaoRP container" id="depositions">
  <h2 class="tittle">Depoimentos</h2>
  <p>O que nossos colaboradores tem a dizer? <span class="heart">&#10084;</span></p>

  <owl-carousel-o [options]="slideDepoimentos">
    <ng-template carouselSlide>
      <div class="cont-item">
        <img class="user" src="assets/img/lydia.png"  alt="Random third slide">
        <p>
          <span class="aspas aspasleft"><img class="aspas" src="assets/img/aspas-left.png" alt="Aspas esquerda"> </span>
          A Redepos é única! Juntos, transformamos grandes ideias em novos produtos. A empresa conecta cada vez mais pessoas, gerando ainda mais criatividade e inovação, e nos dando oportunidade de crescimento profissional.
          <span class="aspas aspasright"><img src="assets/img/aspas-right.png" alt="Aspas direita"></span>
        </p>
      </div>
      <div class="cont-caption">
        <h4>Lydia Lima</h4>
        <p>Analista e UI Designer</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="cont-item">
        <img class="user" src="assets/img/morgana.png"  alt="Random third slide">
        <p>
          <span class="aspas aspasleft"><img src="assets/img/aspas-left.png" alt="Aspas esquerda"></span>
          Na RedePos eu tenho segurança, flexibilidade e a oportunidade de compartilhar meu conhecimento colaborando com o crescimento da empresa. É maravilhoso trabalhar com novas tecnologias, projetar soluções UX e adquirir mais conhecimento. Sou eternamente grata à RedePos por tudo que me proporciona.
          <span class="aspas aspasright"><img src="assets/img/aspas-right.png" alt="Aspas direita"></span>
        </p>
      </div>
      <div class="cont-caption">
        <h4>Morgana Maria</h4>
        <p>UX - Desenvolvedora Front End</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="cont-item">
        <img class="user" src="assets/img/werbeth.png"  alt="Random third slide">
        <p>
          <span class="aspas aspasleft"><img src="assets/img/aspas-left.png" alt="Aspas esquerda"></span>
          A Redepos é especial. Todos os dias acordo tendo a certeza de que estarei superando os meus limites.
          <span class="aspas aspasright"><img src="assets/img/aspas-right.png" alt="Aspas direita"></span>
        </p>
      </div>
      <div class="cont-caption">
        <h4>Werbeth de Sousa</h4>
        <p>DevOps Full Stack</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="cont-item">
        <img class="user" src="assets/img/diovanne.png"  alt="Random third slide">
        <p>
          <span class="aspas aspasleft">
            <img src="assets/img/aspas-left.png" alt="Aspas esquerda">
          </span>
          A Redepos mudou o rumo da minha carreira em TI. Me sinto desafiado todos os dias a ser um profissional melhor. Sou incentivado e reconhecido a cada degrau que subo!
          <span class="aspas aspasright">
            <img src="assets/img/aspas-right.png" alt="Aspas direita">
          </span>
        </p>
      </div>
      <div class="cont-caption">
        <h4>Diovanne Santos</h4>
        <p>Testing Engineer</p>
      </div>
    </ng-template>
    <ng-template carouselSlide>
      <div class="cont-item">
        <img class="user" src="assets/img/mariacireno.png"  alt="Random third slide">
        <p>
          <span class="aspas aspasleft"><img src="assets/img/aspas-left.png" alt="Aspas esquerda"></span>
          Temos oportunidade de conhecer e aprender diversas tecnologias! A empresa valoriza os colaboradores e incentiva nosso crescimento.
          <span class="aspas aspasright"><img src="assets/img/aspas-right.png" alt="Aspas direita"></span>
        </p>
      </div>
      <div class="cont-caption">
        <h4>Maria Cireno</h4>
        <p>Gerente de Projetos</p>
      </div>
    </ng-template>

  </owl-carousel-o>
</section>
